.footer {
    grid-area: footer;
    background-color: #14191e;
    color: white;
    font-size: 1em;
}

.footer-box {
    display: flex;
    justify-content: center;
    height: 25px;
}
