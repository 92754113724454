img {
    margin: 0px 15px 0px 0px;
}
.btn.login-btn {
    color: white;
    background-color: #a3be8c;
    border-color: #a3be8c;
}
.btn.login-btn:hover {
    color: #a3be8c;
    background-color: white;
    border-color: #a3be8c;
}

.btn.logout-btn {
    color: white;
    background-color: #bf616a;
    border-color: #bf616a;
}
.btn.logout-btn:hover {
    color: #bf616a;
    background-color: white;
    border-color: #bf616a;
}
