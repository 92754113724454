.post-title {
    color: black;
}

@media (max-width: 500px) {
    .post-content {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media (min-width: 501px) {
    .post-content {
        padding-left: 20px;
        padding-right: 20px;
    }
}

/* General Styles */
.post-content {
    font-family: "Roboto", sans-serif;
    line-height: 1.6;
    color: black;
    background-color: white;
}

.post-content h1 {
    color: #b48ead;
    font-size: 32px;
    margin-top: 12px;
    margin-bottom: 8px;
    font-weight: 700;
}
.post-content h2 {
    color: #a3be8c;
    font-size: 28px;
    margin-top: 16px;
    margin-bottom: 12px;
    font-weight: 500;
}
.post-content h3 {
    color: #ebcb8b;
    font-size: 24px;
    margin-top: 15px;
    margin-bottom: 12px;
}
.post-content h4 {
    color: #d08770;
    font-size: 20px;
    margin-top: 16px;
    margin-bottom: 4px;
}
.post-content h5 {
    color: #bf616a;
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 4px;
}
.post-content h6 {
    color: #b48ead;
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 4px;
    font-weight: 300;
}

.post-content p {
    margin-bottom: 0px;
}

.post-content em {
    color: #81a1c1;
}

.post-content strong {
    color: #5e81ac;
}

/* Links */
.post-content a {
    color: #5e81ac;
    text-decoration: none;
}

.post-content a:hover {
    text-decoration: underline;
}

.post-content ul {
    margin-left: 12px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding: 0px;
}
.post-content ol {
    margin-left: 12px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding: 0px;
}

.post-content ul li {
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.post-content blockquote {
    margin: 16px 0;
    padding-left: 20px;
    border-left: 4px solid #cccccc;
    color: #81a1c1;
    font-style: italic;
}

.post-content code {
    font-family: "Courier New", Courier, monospace;
    display: block;
    background-color: #333333;
    color: #a3be8c;
    padding: 2px 4px;
    font-size: 110%;
    border-radius: 4px;
}

.post-content img {
    margin-top: 15px;
    max-width: 100%;
    height: auto;
}
